
// Reload

.reload {
  animation: float 5s infinite ease-in-out;
  border-radius: 5px;
  background: color(silver-tree);
  color: color(white);
  font-size: 14px;
  left: 0;
  margin: auto;
  padding: 3px;
  position: absolute;
  right: 0;
  text-align: center;
  width: 180px;

  &:hover {
  	cursor: pointer;
  }

	&__icon {
    margin-right: 10px
	}

	@media #{$large-up} {
		outline: none;
	}
}
