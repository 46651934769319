
// Modal

.modal {
	background: color(white);
	border-radius: 7px;
	box-shadow: 0 0 1px 1px rgba(0,0,0,0.1);
	width: 200px;
	height: 290px;
	left: 20px;
	right: auto;
	top: 75px;
	position: absolute;
	overflow: hidden;
	z-index: 1000;

	@media #{$medium-up} {
		width: 270px;
		height: 370px;
		left: 20px;
		top: 95px;
	}

	@media #{$large-up} {
		left: 230px;
		right: auto;
		top: 110px;
	}

	&__actions {
		border-bottom: 1px solid color(ebb);
		height: 35px;

		@media #{$medium-up} {
			height: 50px;
		}

		&-item {
			cursor: pointer;
			padding: 5px 5px;
			width: 80px;
			text-align: center;
			font-size: 25px;
			top: 1px;
			position: absolute;

			@media #{$medium-up} {
				padding: 5px 7px;
				width: 110px;
				top: 5px;
			}

			&--left {
				border-right: 1px solid color(ebb);
				left: 0;
			}

			&--right {
				border-left: 1px solid color(ebb);
				right: 0;
			}
		}
	}

	&__body {
		position: relative;
		margin: 5px 10px;
	}

	&__close {
		font-size: 8px;
		position: absolute;
		top: -3px;
		right: 3px;

		&:hover {
			cursor: pointer;
		}

		@media #{$medium-up} {
			font-size: 15px;
			top: 0;
			right: 4px;
		}

		&-icon {

			&--outer {
				color: color(white);
			}

			&--inner {
				color: color(white);
			}
		}
	}

	&__content {
		height: 100%;
		position: relative;
	}

	&__footer {
	  border-top: 1px solid color(ebb);
	  height: 28px;
	  margin: auto 10px;
	  padding: 6px 0;
	  position: absolute;
	  bottom: 0;
	  left: 0;
	  right: 0;
	  z-index: 1;

	  @media #{$medium-up} {
	  	height: 35px;
	  	padding: 10px 0 12px;
	  }
	}

	&__foursquare {

		&-button {
			border-radius: 20px;
			border: 1px solid;
			color: color(violet-red);
			display: block;
			font-size: 13px;
			font-weight: 400;
			margin: auto;
			padding: 1px;
			text-align: center;
			text-decoration: none;
			width: 175px;

			@media #{$medium-up} {
				font-size: 15px;
				width: 230px;
				padding: 5px;
			}
		}

		&-icon {
			font-size: 13px;
			margin-right: 6px;
		}
	}

	&__header {
		background: color(outer-space);
		position: relative;
		height: 100px;
		overflow: hidden;
		z-index: 2;

		@media #{$medium-up} {
			height: 140px;
		}

		&-top-bar {
			background: color(jungle-green);
			position: absolute;
			top: 0;
			left: 0;
			height: 20px;
			width: 100%;
			z-index: 3;

			@media #{$medium-up} {
				height: 25px;
			}
		}

		&-bottom-bar {
			background: color(jungle-green);
			height: 3px;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
		}
	}

	&__image {
		background-size: cover;
		height: 100%;
	}

	&__info {
		position: relative;

		&-text {
			color: color(rolling-stone);
			font-size: 10px;
			line-height: 1.2;

			@media #{$medium-up} {
				font-size: 11px;
			}

			&--address {
				font-size: 10px;
				max-height: 58px;
				overflow: hidden;
			}

			&--name {
				color: color(tundora);
				margin-bottom: 4px;
				font-weight: 600;
			}

			&--link {
				color: color(tundora);
				display: inline-block;
				text-decoration: none;
				font-weight: 600;
				width: 100%;
			}
		}

		&-icon {
			color: color(gray-chateau);
			font-size: 14px;
			position: absolute;
			left: 0;
			top: 6px;

			@media #{$medium-up} {
				font-size: 15px;
			}
		}

		&-item {
			position: relative;
			padding: 7px 5px 7px 20px;

			@media #{$medium-up} {
				padding: 7px 5px 7px 25px;
			}

			&--left {
				min-height: 40px;
			}

			&--right {
				border-top: 1px solid color(ebb);
			}
		}
	}

	&__loading {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		background: transparent;
		z-index: 2;
		width: 30px;
		height: 30px;
		margin: auto;

		&-loader {
			@extend %loader;
			width: 30px;
		  height: 30px;
			border-width: 4px;
		  top: 40px;
		  left: 0;
		  right: 0;
		  margin: auto;

		  @media #{$medium-up} {
		  	width: 40px;
		  	height: 40px;
		  	border-width: 5px;
		  	top: 57px;
		  }
		}
	}

	&__overlay {
	  background: rgba(255, 255, 255, 0.95);
	  border: 8px solid rgba(218, 222, 227, 0.9);
	  box-sizing: border-box;
	  height: 100%;
	  padding: 5px;
	  position: absolute;
	  top: 0;
	  width: 100%;
	  z-index: 9999;

	  &-message {
			left: 0;
			margin: auto;
			position: absolute;
			right: 0;
			text-align: center;
			top: 150px;
	  }

	  &-phone {
	  	height: 130px;
		  font-family: 'Lato', sans-serif;
		  font-size: 20px;
		  font-weight: 300;
		  margin: 65px 0;
		  text-align: center;

		  &::selection {
		  	background: none;
		  }

		  @media #{$medium-up} {
		  	font-size: 25px;
		  }
	  }

		&-button {
			display: inline-block;

			&--cancel {
			  position: absolute;
			  bottom: 0;
			}

			&--copy {
				display: none;

				@media #{$medium-up} {
					display: inline-block;
				}
			}
	  }

	  &-actions {
		  bottom: -8px;
		  height: 92px;
		  left: 0;
		  margin: 0;
		  margin: auto;
		  padding: 0;
		  position: absolute;
		  right: 0;
		  width: 96%;
	  }

		&-button-group {
			position: absolute;
			top: 0;
			height: 40px;
	  }
	}

	&__phone {
		font-size: 20px;

		@media #{$medium-up} {
			font-size: 25px;
		}
	}

	&__price {
		border-radius: 3px;
		background: rgba(255,255,255,0.3);
		font-size: 11px;
		height: 17px;
		left: 7px;
		line-height: 1.6;
		position: absolute;
		text-align: center;
		top: 30px;
		width: 50px;
	}

	&__rating {
		background: color(white);
		border-radius: 4px;
		border: 2px solid color(jungle-green);
		color: color(white);
		display: inline-block;
		font-size: 7px;
		font-weight: 300;
		height: 9px;
		left: 0;
		line-height: 9px;
		margin: auto;
		position: absolute;
		right: 0;
		text-align: center;
		top: -11px;
		vertical-align: middle;
		width: 60px;
		z-index: 2;

		@media #{$medium-up} {
			font-size: 9px;
			height: 11px;
			line-height: 11px;
			width: 75px;
		}
	}
}
