
// Header

.header {
	outline: none;

	&__heading {
		color: color(white);
    left: 20px;
    position: absolute;
    top: 20px;
	}
}
