
// Range Slider

.range-slider {
  width: 100%;
  margin: 7.3px 0;
  background-color: transparent;
  appearance: none;

  &::range-track {
    background: color(mandy);
    border: 0.2px solid color(mandy);
    border-radius: 1.3px;
    width: 100%;
    height: 11.4px;
    cursor: pointer;
  }

  &::range-thumb {
    margin-top: -7.5px;
    width: 26px;
    height: 26px;
    background: color(mandy);
    border: 1.8px solid color(mandy);
    border-radius: 15px;
    cursor: pointer;
    -webkit-appearance: none;
  }

  &::range-lower {
    background: #ec5565;
    border: 0.2px solid color(mandy);
    border-radius: 2.6px;
  }

  &::range-upper {
    background: color(mandy);
    border: 0.2px solid color(mandy);
    border-radius: 2.6px;
  }

  &:focus {
    outline: none;

    &::range-track {
      background: color(mandy);
    }

    &::range-upper {
      background: color(mandy);
    }

    &::range-lower {
      background: color(mandy);
    }
  }
}
