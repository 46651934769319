
// Content 

html, body {
  background-image: image(default-large);
  background-repeat: repeat;
  background-size: 15%;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  overflow: hidden;
}

.main-content {
	outline: none;
	height: 100%;
	margin: 0;
	position: relative;
	z-index: 1000;

	@media #{$large-up} {
		margin: 0 0 0 203px;
	}
}
