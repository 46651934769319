
// Rating

.rating {
	$this: &;

	unicode-bidi: bidi-override;

	&__star {
		display: inline-block;
		font-family: 'Font Awesome 5 Pro';
		font-style: normal;
		font-weight: normal;
		line-height: inherit;

		&:before {
		  content: "\f005";
			font-weight: bold;
		  padding: 1px;
		  color: color(silver-sand);
		}
	}

	&--00 {

		#{$this}__star {
			display: none;
		}

		&:before {
			content: 'No Ratings';
			color: color(gray);
		  display: block;
		}
	}

	&--01 {

  	#{$this}__star--01:before {
			color: color(chenin);
  	}
  }

  &--02 {

	  #{$this}__star--01:before,
		#{$this}__star--02:before {
			color: color(chenin);
  	}
  }

  &--03 {

	  #{$this}__star--01:before,
		#{$this}__star--02:before,
		#{$this}__star--03:before {
			color: color(chenin);
  	}
  }

  &--04 {

	  #{$this}__star--01:before,
		#{$this}__star--02:before,
		#{$this}__star--03:before,
		#{$this}__star--04:before {
			color: color(chenin);
  	}
  }

  &--05 {

	  #{$this}__star--01:before,
		#{$this}__star--02:before,
		#{$this}__star--03:before,
		#{$this}__star--04:before,
		#{$this}__star--05:before {
			color: color(chenin);
  	}
  }
}
