
// Filter

.filter {
  bottom: 80px;
  height: 35px;
  left: 15px;
  line-height: 1.9;
  position: absolute;
  width: 225px;

	&:hover {
		cursor: pointer;
	}

	@media #{$medium-up} {
		bottom: 90px;
	}

	@media #{$large-up} {
		display: none;
	}

	&__icon {
		border-radius: 999px;
		box-shadow: 0 0 4px 1px rgba(0,0,0,0.3);
		transition: all .5s linear;
		background: color(white);;
		color: color(jungle-green);
		height: 35px;
		left: 0;
		line-height: 2.1;
		position: absolute;
		position: relative;
		top: 0;
		text-align: center;
		vertical-align: middle;
		width: 35px;
		z-index: 3000;

		&.active {
			background: color(outer-space);
			color: color(white);;
		}
	}

	&__input-container {
		border-radius: 0 5px 5px 0;
	  background: #232b2d;
	  height: 31px;
	  line-height: 1.7;
	  padding: 2px;
	  position: absolute;
	  right: 20px;
	  text-align: center;
	  top: 0px;
	  width: 180px;
	  z-index: 2000;
	}

  &__input {
  	border-radius: 2px;
		appearance: none;
		background: color(white);;
		border: 1px solid color(white);;
		margin: 0;
		outline: none;
		padding: 4px;
		vertical-align: middle;
		width: 145px;
  }

  &__clear {
		color: color(silver-sand);
		font-size: 13px;
		font-weight: bold;
		height: 20px;
		line-height: 2.4;
		position: absolute;
		right: 15px;
		text-align: center;
		top: 2px;
		width: 20px;
		z-index: 3000;

	  &:hover {
	  	cursor: pointer;
	  }
	}

  &__input-close {
  	border-radius: 0 3px 3px 0;
	  background: color(mandy);
	  color: color(white);;
	  font-size: 11px;
	  height: 35px;
	  line-height: 2.9;
	  position: absolute;
	  right: -20px;
	  top: 0;
	  vertical-align: middle;
	  width: 25px;
	  text-align: center;
	}
}
