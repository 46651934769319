
// Menu

.menu {
	$this: &;

	background: color(outer-space);
  height: 28px;
  left: 0;
  margin: 0;
  padding: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 3000;

	&:before {
		box-shadow: inset -4px 0 5px -5px color(tundora);
		text-shadow: color(tundora) 1px 1px 0px;
	  background: color(outer-space);
	  border-right: 1px solid color(cod-gray);
	  color: color(cod-gray);
	  content: "\f041";
	  display: inline-block;
	  font-family: FontAwesome;
	  font-size: 26px;
	  font-style: normal;
	  font-weight: normal;
	  height: 100%;
	  left: 0;
	  padding: 11px 0;
	  position: absolute;
	  text-align: center;
	  top: 0;
	  width: 63px;
	  z-index: 1;
	}

	@media #{$medium-up} {
		height: 39px;

		&:before {
			font-size: 36px;
			padding: 17px 0;
		}
	}

  @media #{$large-up} {
  	height: 100%;
  	top: 0;
  	margin-top: 82px;
  	padding: 0;
  	width: 203px;
  	overflow: hidden;

  	&:before {
  		display: none;
  	}
  }

  &__filter {
	  display: none;
	  height: 30px;
	  margin: 10px 10px 0 10px;
	  position: relative;
	  width: 183px;

  	@media #{$large-up} {
  		display: block;
  	}

  	&-clear {
		  color: color(silver-sand);
		  font-size: 13px;
		  font-weight: bold;
		  height: 20px;
		  line-height: 1.7;
		  position: absolute;
		  right: 2px;
		  text-align: center;
		  top: 2px;
		  width: 20px;

		  &:hover {
		  	cursor: pointer;
		  }
  	}

		&-input {
	    border-radius: 3px;
		  background: #fefefe;
		  border: 2px solid color(white);
		  height: 25px;
		  padding: 0 4px;
		  width: 100%;
		  box-sizing: border-box;
			appearance: none;

	    &:focus {
	    	outline-color: transparent;
	    	outline-style: none;
	    }
		}
  }

  &__radius {
		padding: 10px 10px 5px 10px;
		display: none;

	  @media #{$large-up} {
			display: block;
	  }
  }

  &__list {
		color: color(white);
		margin: 0 0 0 43px;
		padding: 0 32px 10px 0;
		overflow-y: hidden;
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
		white-space: nowrap;

		&:after {
			border-radius: 999px;
		  background: color(mandy);
		  content: '';
		  display: inline-block;
		  height: 25px;
		  margin: 0 0 -6px 17px;
		  width: 3px;
		}

		@media #{$medium-up} {
			margin: 0 0 0 42px;
			padding: 0 42px 10px 0;

			&:after {
				margin: 0 0 -6px 18px;
				width: 4px;
			}
		}

		@media #{$large-up} {
			height: 70vh;
			margin: 20px 0 0 0;
			padding: 0;
			width: 200px;
			overflow: auto;

			&:after {
				display: none;
			}
		}

  	&-item {
		  color: color(nevada);
		  display: inline-block;
		  font-size: 17px;
		  line-height: 1;
		  margin: 0 0 0 18px;

			@media #{$medium-up} {
				font-size: 18px;
			}

  		@media #{$large-up} {
  			transition: all .2s linear;
  			display: block;
  			font-size: 15px;
  			margin: 0 0 0 8px;
  		}

			&--hover,
		  &--active {
				cursor: pointer;
		  	color: color(white);

		  	#{$this}__list-item-icon {

		  		&--outer {
		  			color: color(white);
		  		}

		  	 	&--inner {
		  			color: color(jungle-green);
		  		}
		  	}
		  }

  		&--hidden {
  			display: none;
  		}

  		&--no-results {
  			color: color(white);

  			#{$this}__list-item-icon {

		  	 	&--inner {
		  			color: color(mandy);
		  		}
  			}
  		}

			&-icon {
				transition: all .5s ease-in-out;
				margin: 0 5px 0 0;
				position: relative;
				top: 2px;

				@media #{$medium-up} {
					margin: 6px 5px 0 0;
					top: 1px;
				}

				@media #{$large-up} {
					margin: 0 5px 0 0;
				}

    		&--inner {
					color: color(nevada);
					font-size: 11px;
					line-height: 2.5;
    		}

    		&--outer {
					color: color(sirocco);
					font-size: 28px;
    		}
  		}
  	}
  }
}
