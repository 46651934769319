
// Uber

.uber {
  $this: &;

  &__icon {
    background-color: color(ebony);
    border-radius: 4px;
    height: 22px;
    margin: auto;
    position: relative;
    width: 85px;

    &--loading {

      &:after {
        display: none;
      }
    }

    &:after {
      position: absolute;
      font-family: 'Font Awesome 5 Brands';
      content: "\f402";
      color: color(white);
      font-size: 14px;
      left: 5px;
      top: 0;
    }

    @media #{$medium-up} {
      height: 26px;
      width: 117px;
      background-position: 6px;

      &:before {
        color: color(silver);
        content: 'Ride with Uber';
        font-size: 8px;
        height: 10px;
        left: 30px;
        line-height: 1;
        position: absolute;
        top: 3px;
      }

      &:after {
        font-size: 18px;
        top: 1px;
      }
    }
  }

  &__estimate {
    color: color(white);
    font-size: 9px;
    font-weight: $font-light;
    left: 31px;
    line-height: 1.2;
    overflow: hidden;
    position: absolute;
    text-align: left;
    text-overflow: ellipsis;
    top: 11px;
    white-space: nowrap;
    width: 55px;

    @media #{$medium-up} {
      font-size: 9px;
      left: 38px;
      top: 18px;
      width: 80px;
    }
  }

  &__loader {
    @extend %loader;
  	background: color(ebony);
  	border-color: color(java);
  	border-top-color: transparent;
    border-width: 2px;
    height: 15px;
    left: 5px;
    margin: auto;
    top: 3px;
    width: 15px;
    z-index: 9999;

    @media #{$medium-up} {
  		height: 16px;
  		left: 7px;
  		top: 5px;
  		width: 16px;
    }
  }
}
