
// Buttons

.button {
	appearance: none;
	border-radius: 0;
	border-style: solid;
	border-width: 1px;
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	font-family: 'Lato', sans-serif;
	font-size: 15px;
	font-weight: normal;
	line-height: normal;
	margin: 0 0 15px;
	padding: 7px;
	position: relative;
	text-align: center;
	text-decoration: none;

	&:focus {
		outline: 0;
	}

	&--default {
		background-color: color(mischka);
		border-color: color(mischka);
		color: color(dove-gray);
	}

	&--action {
		background-color: color(silver-tree);
		color: color(white);
		border-color: color(silver-tree);
	}

	&--cancel {
		background-color: color(mandy);
		color: color(white);
		border-color: color(mandy);
	}

	&--plain {
		background-color: transparent;
		border-color: color(white);
		color: color(white);
	}

	&--radius {
		border-radius: 3px;
	}

	&--expand {
		width: 100%;
	}

}
