
// Media
// Zurb Foundation Media Queries 
// @see {@link http://foundation.zurb.com/sites/docs/v/4.3.2/media-queries.html}

// This is the default html and body font-size for the base rem value.
$rem-base: 16px !default;

// RANGES
// We use these functions to define ranges for various things, like media queries.
@function lower-bound($range) {
  @if length($range) <= 0 {
    @return 0;
  }
  @return nth($range, 1);
}

@function upper-bound($range) {
  @if length($range) < 2 {
    @return 999999999999;
  }
  @return nth($range, 2);
}

@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@function convert-to-rem($value, $base-value: $rem-base) {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;
  @if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
  @return $value;
}

@function rem-calc($values, $base-value: $rem-base) {
  $max: length($values);

  @if $max == 1 { @return convert-to-rem(nth($values, 1), $base-value); }

  $remValues: ();
  @for $i from 1 through $max {
    $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
  }
  @return $remValues;
}

@function em-calc($values, $base-value: $rem-base) {
  $remValues: rem-calc($values, $base-value: $rem-base);

  $max: length($remValues);

  @if $max == 1 { @return strip-unit(nth($remValues, 1)) * 1em; }

  $emValues: ();
  @for $i from 1 through $max {
    $emValues: append($emValues, strip-unit(nth($remValues, $i)) * 1em);
  }
  @return $emValues;
}

// Here we define the breakpoints which will become the upper border for each media size.
// The function em-calc() calculates the em-value from a px-value.
$small-breakpoint:  em-calc(640)  !default;
$medium-breakpoint: em-calc(1024) !default;
$large-breakpoint:  em-calc(1440) !default;
$xlarge-breakpoint: em-calc(1920) !default;

// Here we define the lower and upper bounds for each media size
$small-range:   (0, $small-breakpoint) !default; /* 0, 640px */
$medium-range:  ($small-breakpoint  + em-calc(1), $medium-breakpoint) !default; /* 641px, 1024px */
$large-range:   ($medium-breakpoint + em-calc(1), $large-breakpoint)  !default; /* 1025px, 1440px */
$xlarge-range:  ($large-breakpoint  + em-calc(1), $xlarge-breakpoint) !default; /* 1441px, 1920px */
$xxlarge-range: ($xlarge-breakpoint + em-calc(1), em-calc(99999999)) !default; /* 1921px, ... */

// Media Queries
$screen: "only screen" !default;

$landscape: "#{$screen} and (orientation: landscape)" !default;
$portrait: "#{$screen} and (orientation: portrait)" !default;

$small-up: $screen !default;
$small-only: "#{$screen} and (max-width: #{upper-bound($small-range)})" !default;

$medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})" !default;
$medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})" !default;

$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})" !default;
$large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})" !default;

$xlarge-up: "#{$screen} and (min-width:#{lower-bound($xlarge-range)})" !default;
$xlarge-only: "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})" !default;

$xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})" !default;
$xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})" !default;

$retina: (
  "#{$screen} and (-webkit-min-device-pixel-ratio: 2)",
  "#{$screen} and (min--moz-device-pixel-ratio: 2)",
  "#{$screen} and (-o-min-device-pixel-ratio: 2/1)",
  "#{$screen} and (min-device-pixel-ratio: 2)",
  "#{$screen} and (min-resolution: 192dpi)",
  "#{$screen} and (min-resolution: 2dppx)"
);
