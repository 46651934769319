
// Notification

.notification {
  animation: buzz .2s infinite linear;
  background: color(mandy);
  border-radius: 5px;
  color: color(white);
  display: none;
  font-size: 13px;
  height: 10px;
  left: 0;
  line-height: 0.8;
  margin: auto;
  opacity: 0;
  padding: 5px;
  position: absolute;
  right: 0;
  text-align: center;
  transition: opacity 200ms linear;
  width: 200px;
  z-index: 1000;

  @media #{$large-up} {
    font-size: 14px;
    font-size: 14px;
    height: 15px;
    line-height: 1.1;
    right: 0;
  }
}
