
// Landing

.landing {
  $this: &;

  background-color: color(gray);
  background-size: 100%;
  background-image: image(default-large);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5000;
  animation: 100s scroll infinite linear;

	&:before {
		content: '';
		background: rgba(color(jungle-green), 0.5);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	@media #{$medium-up} {
		background-size: 85%;
	}

	@media #{$large-up} {
		background-size: 35%;
	}

  &__content {
    position: absolute;
    top: 20%;
    right: 0;
    left: 0;
    margin: auto;

    @media #{$xlarge-up} {
      top: 280px;
    }

    @media #{$xxlarge-up} {
      top: 300px;
    }
  }

	&__header {
		color: color(off-white);;
		font-size: 48px;
		font-weight: $font-extra-bold;
		margin: auto;
    margin-bottom: 40px;
		min-width: 200px;
		padding: 10px;
		text-align: center;

	  @media #{$medium-up} {
	  	font-size: 52px;
	  	letter-spacing: 3px;
	  }

    @media #{$large-up} {
      font-size: 62px;
    }

    @media #{$xlarge-up} {
      font-size: 72px;
      margin-bottom: 82px;
    }

    @media #{$xxlarge-up} {
      font-size: 80px;
      margin-bottom: 92px;
    }
	}

	&__info {
	  color: color(off-white);;
	  font-size: 18px;
	  font-weight: $font-light;
	  margin: auto;
    margin-bottom: 20px;
	  text-align: center;
	  max-width: 280px;

	  @media #{$medium-up} {
	  	font-size: 19px;
	  	max-width: 500px;
	  }

    @media #{$xlarge-up} {
      font-size: 23px;
      max-width: 700px;
      margin-bottom: 48px;
    }

    @media #{$xxlarge-up} {
      font-size: 25px;
      margin-bottom: 50px;
    }
	}

  &__loader {
    width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;

  	&-outer {
  		animation: pulse-fade 2.1s linear infinite;
  		border-radius: 999px;
  		transform: scale(0.2);
  		background: rgba(color(white), 0.1);
  		border: 3px solid color(gossamer);
  		bottom: 0;
  		height: 250px;
  		left: 0;
  		margin: auto;
  		position: absolute;
  		right: 0;
  		top: 0;
  		width: 250px;

  		@media #{$medium-up} {
  			width: 400px;
  			height: 400px;
  		}
  	}

  	&-inner {
  		animation: pulse-fade 2.1s -.5s ease-out infinite;
  		border-radius: 999px;
  		transform: scale(0.2);
  		background: rgba(color(white), 0.1);
  		border: 3px solid color(gossamer);
  		bottom: 0;
  		height: 180px;
  		left: 0;
  		margin: auto;
  		position: absolute;
  		right: 0;
  		top: 0;
  		width: 180px;
  		z-index: 1;

  		@media #{$medium-up} {
  			width: 350px;
  			height: 350px;
  		}
  	}
  }

	&__action {
	  margin: auto;
	  padding-top: 20px;
	  width: 250px;

    @media #{$medium-up} {
	  	width: 270px;
	  }

	  &-button {
	  	background-color: color(silver-tree);
	  	border-color: color(silver-tree);
	  	color: color(white);
	  	font-weight: 900;
	  	letter-spacing: 1px;
	  	text-transform: uppercase;
	  	padding: 15px;
	  	position: relative;
	  	transition: all .5s linear;

	  	&:hover {
	  		background-color: color(boulder);
	  		border-color: color(boulder);
	  	}
	  }
	}

	&__powered {
	  bottom: 15%;
	  height: 25px;
	  left: 0;
	  margin: auto;
	  position: absolute;
	  right: 0;
	  width: 124px;
  }

	&__powered-header {
	  color: color(off-white);;
	  font-size: 8px;
	  letter-spacing: 1px;
	  position: absolute;
	  text-align: center;
	  text-transform: uppercase;
	  top: -20px;
	  width: 100%;
	}

	&__brand-list {
		list-style: none;
		text-align: center;
		width: 100%;
  }

	&__brand-item {
		background: rgba(255, 255, 255, 0.95);
		display: inline-block;
		width: 40px;
		float: left;

    &--foursquare {
			border-radius: 3px 0 0 3px;

			#{$this}__brand-icon {
				color: #f94877;
			}
		}

		&--uber {
		  margin: 0 2px;
		}

		&--google {
			border-radius: 0 3px 3px 0;

			#{$this}__brand-icon {
				color: #dd4b39;
			}
		}
  }

  &__brand-link {
    outline: none;
  }

	&__brand-icon {
		color: color(tundora);
		font-size: 14px;
		padding: 3px;
	}

	&__notification {
		top: 220px;
		z-index: 5000;

		@media #{$medium-up} {
			top: 250px;
		}

		@media #{$large-up} {
			top: 260px;
		}
	}

	&__reload {
		top: 155px;
		z-index: 5000;

		@media #{$medium-up} {
			top: 177px;
		}

		@media #{$large-up} {
			top: 187px;
		}
	}
}
